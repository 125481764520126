
































































import GenericForm from '@/components/forms/GenericForm.vue'

import {
  Home
} from '../../router';

import {Store} from '@/store/store'
import Component from 'vue-class-component'
import {Action} from 'vuex-class'

@Component({
  components: {
    GenericForm
  },
  metaInfo: {
    title: 'Edit Reseller Account'
  }
})

export default class EditResellerAccount extends Store {
  componentTitle = 'View Reseller Account'
  resellerAccount: any = {}
  Home = Home

  @Action('getResellerAccount') getResellerAccount: any
  
  async mounted(){
    if(!this.$route.params.id){
      this.$toast.error('Invalid ID Given')
      return
    }
    await this.refresh()    
  }

  async refresh(){
    const id = this.$route.params.id
    const {business_profile} = await this.getResellerAccount(id)
    this.resellerAccount = business_profile
  }

  deleteAccount(){
    this.$toast.error('Not Implemented Yet')
  }
}
